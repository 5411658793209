import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgProfileDaveHackshaw from "../images/profile-dave-hackshaw.jpg"
import imgClient from "../images/cambridge-buses-cover-photo.jpg"
import "./index.css";
import "./case-study.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Case study: How Cambridge Buses use ShuttleID to help their local authority save time and money" />
        <Nav activeTab="case-studies" />
        <div>
            <div>

              <div className="case-study">

                <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                  <h1 className="blog-headline">How Cambridge Buses use ShuttleID to help their local authority save time and money</h1>
                </div>

                <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                  <h1 className="blog-headline">How Cambridge Buses use ShuttleID to help their local authority save time and money</h1>
                </div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>With their expertise in efficient route design and precision in vehicle capacity management, Cambridge Buses created a unique proposition that saves their local authority time and money using a pay per passenger scheme.</p>
                    <p>This ambitious model, however, proved difficult to operate without the data of who was boarding their services. Over the years they tried many ways to administer bus passes but cost, time and complexity would become overwhelming.</p>
                    <p>Using ShuttleID, Cambridge Buses can now easily distribute, manage and monitor bus passes with accuracy in a cost-effective system that everyone finds simple to use.</p>
                  </div>
                  <div className='article-inner'>
                    <div className='case-study__metrics'>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>£70,000</span>
                        council saving on transport per school per year
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>624 hours</span>
                        operator admin saved per year
                      </div>
                    </div>
                    <div className='case-study__about'>
                      <h3>About Cambridge Buses</h3>
                      <p><a href="https://www.cambridgebuses.co.uk/">Cambridge Buses</a> are focussed on home-to-school transport and operate a fleet of high-capacity double decker buses transporting approximately 2000 students to and from school each day in the Cambridge area. The management have been involved in the industry for over 40 years.</p>
                    </div>

                    <h2>Chink in the armour</h2>
                    <p>Like many local authorities, Cambridgeshire County Council are continually looking at ways to achieve cost savings on their home-to-school transport contracts. At a time when the cost of fuel was increasing, employee wages rising and overheads generally on the up, reducing prices using traditional contracting approaches further would result in commercially un-viable services.</p>
                    <p>Cambridge Buses instead proposed a unique approach to help their local authority. With a one school one operator (OSOO) model, they could reduce the amount of vehicles required by an average of 30% and also remove the burden of routing, timetabling, and administering bus passes, resulting in significant cost savings for the council through actual efficiency.</p>
                    <p>Although the savings were quickly realised by the local authority, the model proved a challenge to manage consistently for Cambridge Buses, with many unexpected hurdles to overcome.</p>

                    <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileDaveHackshaw} alt="Dave Hackshaw" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p>"To use as few vehicles as possible we had to do two things: one was to be really strong on routing and making sure the full capacity is used and the other thing was make sure only authorised students can get on that vehicle."</p>
                        <p>"Initially when we used plastic ID cards with the students’ photo on, it had a high degree of compliance whilst they were trying to figure out where the chinks were in the system. The flaw they ultimately found was that if they defaced or "lost" their pass, we essentially had no clue which vehicle they were meant to be loaded on. It would take us weeks to actually get to the bottom of whether they had or hadn't got their pass."</p>
                        <p>"We started out with a list of just a dozen students that had forgotten their pass each week. Towards the end we would have lists with 30, 40, 50 travelling without a pass. We can't stop 50 students travelling, our job is to get students into school."</p>
                        <p className='case-study__profile-quote__author'>Dave Hackshaw, Commercial Director, Cambridge Buses</p>
                      </div>
                    </div>

                    <p>Initially using plastic photo IDs, the challenges quickly became apparent. Firstly, the passes ended up creating more admin than anticipated; the process relied on the council to produce a list of authorised travellers, then working out loading lists, producing the physical passes and then posting them out. The process was then further complicated as photos would not be available from the school for use on the pass until after the first half term, resulting in complexity of managing temporary paper passes.</p>
                    <p>Secondly, it was only a matter of time before a sizeable minority of passengers started to realise how to exploit the system and gain a ride on services they were not allocated to, putting risk to the whole operational model which completely depended on exact passengers boarding the correct vehicles.</p>

                    <h2>Running out of options</h2>
                    <p>Cambridge Buses explored many solutions over the years but none proved ideal. Bus pass scanners from the big ticketing machine companies were too costly, with each vehicle needing investment of over £1,000 just on initial hardware outlay. The complexity of the hardware also meant drivers would require intensive training at the cost of the operator.</p>
                    <p>Generic RFID card reading still proved expensive and very complex to setup. A further experiment with biometrics (fingerprint scanning) had similar disadvantages but was ultimately mothballed when it caused serious delay to boarding time.</p>
                    <p>Cambridge Buses were running out of options before finally discovering a new system, ShuttleID; a bus pass system that saves time, is simple to use and cost-effective.</p>
                    <div className='case-study__quote'>
                      <p>"We always had an ambition to have some form of simple and efficient bus pass checker. Up until now they've either been super complex or just not out there at all."</p>
                      <p className='case-study__quote-author'>&mdash; Dave Hackshaw, Commercial Director, Cambridge Buses</p>
                    </div>
                    <p>ShuttleID's Simple Package provided:</p>
                    <ul>
                      <li>A waiting list for parents to register their interest</li>
                      <li>A back office for operators to import passengers and assign digital bus passes</li>
                      <li>Bus passes with QR codes that can be used directly on phones or printed</li>
                      <li>Simple pass scanning using consumer grade hardware available from £40 per vehicle</li>
                      <li>Operational data that allows operators to see exactly who boards their services</li>
                      <li>SMS based service alerts for critical messaging when there is service disruption</li>
                    </ul>

                    <h2>"Even better than we envisaged"</h2>
                    <p><img src={imgClient} alt="Cambridge Buses" /></p>
                    <p>The solution has proved to be the missing piece in their operation at Cambridge Buses. Using the waiting list feature, parents signed their children up for bus passes, which Cambridge Buses cross checked with authorised traveller lists from the council.</p>
                    <p>The process of distributing bus passes at volume now becomes a much easier task through the click of a button. More importantly, it has closed the loophole of defaced or "forgotten" passes, as passengers will now have a copy on their phone but also a copy printed out as a backup - giving little reason for passengers to turn up without a pass.</p>
                    <p>It has also removed the replacement pass fee previously incurred by parents. Instead, parents always have a copy accessible in their email inbox which can be resent to the passenger or printed out without having to involve the operator or the local authority.</p>
                    <p>Hackshaw was impressed with how simple it was to use and set up: "One of the things we really loved was the simplicity and simplicity for the driver and, frankly, simplicity for us to set the system up."</p>

                    <p>"The introduction has been really smooth and really quick. Just in the first two weeks, you can see that everyone has got it. Would we have been in this place with the existing system? No. We'd have been running around handing out plastic passes, finding a bunch of kids that don't have passes, giving 170 bits of paper to year 7s and waiting for their photos to come through. Through this and our vehicle management system, we've managed to drop two days per week of admin out of the office." said Hackshaw.</p>
                    <div className='case-study__quote'>
                      <p>"It's worked even better than we envisaged. The drivers have got on board with it. The students, we already knew they'd pick it up very quickly, they all like using it. They don't have to show anything to the driver, they can literally just go *ping* and they're on. Parents as well, they like it, they've got control over it, they've got it."</p>
                      <p className='case-study__quote-author'>&mdash; Dave Collier, Director, Cambridge Buses</p>
                    </div>
                    <p>Now armed with data of who boards their vehicles, the time and cost savings afforded to Cambridge Buses help guarantee their commitment to helping the local authority efficiently reduce their home-to-school expense.</p>
                    <p>With a formula they've perfected over years of experience and now with the right technology, Cambridge Buses are looking forward to exploring more ways in helping local authorities become more efficient with their home-to-school budgets.</p>



                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
